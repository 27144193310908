import React from "react";
import { connect } from "react-redux";
// import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import InventoryCarCard from "./component";
import { clearSimilarCarList, fetchCarDetails, fetchSimilarCars, prePopulateCarDetails, setGsGalleryData } from "../car-details/actions";
import { saveCheckoutSourcePathname } from "../checkout-info/actions";
import { addCarToCompare, removeCarFromCompare} from "../car-listing/actions";
import { openLoginPopup, setLoginType } from "../../au.configuration/actions";

const mapStateToProps = (
    {
        user: {
            email,
            secureToken,
            isLoggedIn
        },
        filters: {
            allFilters,
            appliedFilters,
            selectedSort,
            listingType
        },
        homeCarList: {
            saleConfig
        },
        abExperiment: {
            showCdpGalleryVariant,
            showTestDriveV2,
            showPriceConfidence,
            showFinanceFirstCheckout
        },
        tradeIn: {
            userTradeInData,
            tradeInToggleSelected
        },
        user: {
            isUserZeroDpVariant
        },
        carDetails: {
            content: {
                appointmentId: cdpAppId
            }
        }
    }
) => ({
    allFilters,
    appliedFilters,
    selectedSort,
    saleConfig,
    tradeInToggleSelected,
    userTradeInData,
    email,
    listingType,
    isUserZeroDpVariant,
    showCdpGalleryVariant,
    showTestDriveV2,
    secureToken,
    isLoggedIn,
    showPriceConfidence,
    cdpAppId,
    showFinanceFirstCheckout
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchCarDetailsConnect: fetchCarDetails,
    saveCheckoutSourcePathnameConnect: saveCheckoutSourcePathname,
    addCarToCompareConnect: addCarToCompare,
    removeCarFromCompareConnect: removeCarFromCompare,
    prePopulateCarDetailsConnect: prePopulateCarDetails,
    setLoginTypeConnect: setLoginType,
    openLoginPopupConnect: openLoginPopup,
    setGsGalleryDataConnect: setGsGalleryData,
    fetchSimilarCarsConnect: fetchSimilarCars,
    clearSimilarCarListConnect: clearSimilarCarList
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InventoryCarCard);
