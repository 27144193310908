/* eslint-disable no-nested-ternary */
/* eslint-disable max-statements */
/* eslint-disable complexity */
import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";

import PriceDeal from "./images/price-deal.svg";

const PriceDealLabel = ({onClickPriceLabel}) => {
    return (
        <div styleName={"styles.priceDetalWrapper"} onClick={onClickPriceLabel}>
            <img src={PriceDeal} alt= "dollar sign" />
            <button styleName={"styles.priceDeal"}>VALUE DEAL</button>
        </div>
    );
};

PriceDealLabel.propTypes = {
    onClickPriceLabel: PropTypes.func
};

export default PriceDealLabel;
